import Carousel from "react-bootstrap/Carousel";
import { styles } from "./cover_section";
import Button from "@mui/material/Button";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { colors } from "../../utils/const";
import Link from "next/link";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import Steps from "../../components/steps/stepsComp";
import Image from "next/image";

export default function Cover() {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  const isRTL = locale === "ar";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Carousel
      controls={true}
      fade={true}
      interval={null}
      dir={t === "ar" ? "rtl" : "ltr"}
      className={locale === "ar" ? "rtl-carousel" : ""}
    >
      {t.home_page.slides.map((item, index) => {
        // const videoSrc = isMobile ? item.mobileSrc : item.desktopSrc;
        const srcResponsive = isMobile ? item.mobileSrc : item.desktopSrc;

        return (
          <Carousel.Item key={index}>
            {item.type === "contact-now" ? (
              <Steps />
            ) : (
              <Box
                sx={{
                  height: { xs: "700px", md: "400px", lg: "600px" },
                  color: "white",
                  background: `url(${item.bgImage})`,
                  backgroundSize: "cover",
                  // ...(item.banner_type !== "video" && {
                  //   display: "flex",
                  //   flexDirection: { xs: "column", sm: "row" },
                  //   justifyContent: "space-around",
                  //   alignItems: "center",
                  //   gap: 6,
                  //   position: "relative",
                  //   py: 6,
                  //   direction: locale === "en" ? "ltr" : "rtl",
                  // }),
                  ...(item.banner_type !== "img" && {
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-around",
                    alignItems: "center",
                    gap: 6,
                    position: "relative",
                    py: 6,
                    direction: locale === "en" ? "ltr" : "rtl",
                  }),
                }}
              >
                {/* {item.banner_type === "video" ? (
                  <Box
                    sx={{
                      // width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      component="video"
                      src={videoSrc}
                      width="100%"
                      height="100%"
                      autoPlay
                      loop
                      muted
                      playsInline
                      preload="metadata"
                      sx={{
                        objectFit: {
                          xs: "fill",
                          md: "cover",
                        },
                      }}
                    />
                  </Box>
                ) */}
                {item.banner_type === "img" ? (
                  <Box
                    sx={{
                      // width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={srcResponsive}
                      width="100%"
                      height="100%"
                      // autoPlay
                      // loop
                      // muted
                      // playsInline
                      // preload="metadata"
                      sx={{
                        objectFit: {
                          xs: "fill",
                          md: "cover",
                        },
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        order: { xs: 2, sm: 1 },
                        width: {
                          xs: "180px",
                          sm: "150px",
                          md: "200px",
                          lg: "300px",
                        },
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        alignItems: { xs: "center", sm: "flex-start" },
                      }}
                    >
                      <Box
                        sx={{
                          height: { xs: "40px", sm: "60px", md: "100px" },
                        }}
                      >
                        <img
                          src={item.logo}
                          alt="cover"
                          height="100%"
                          loading="lazy"
                        />
                      </Box>
                      <Box
                        sx={{
                          mb: { xs: 1 },
                          fontSize: {
                            xs: "15px",
                            sm: "15px",
                            md: "20px",
                            lg: "25px",
                          },
                          textAlign: { xs: "center", sm: "initial" },
                          color: {
                            xs: index == 2 ? colors.white : item.textColor,
                            sm: item.textColor,
                          },
                        }}
                      >
                        {item.text}
                      </Box>
                      <Link
                        href={item.link}
                        sx={{ "&:hover": { color: colors.white } }}
                      >
                        <Button
                          aria-label={`Read more about ${item.text}`}
                          variant="outlined"
                          sx={styles.btn}
                        >
                          <span> {item.button}</span>

                          <span
                            style={{
                              position: "absolute",
                              clip: "rect(1px, 1px, 1px, 1px)",
                            }}
                          >
                            {locale === "en"
                              ? " read more about e-payment and what you can do with Contact Pay"
                              : "اقرأ المزيد عن كونتكت باي"}
                          </span>
                        </Button>
                      </Link>
                      {item.app && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              mx: {
                                md: "inherit",
                                xs: "auto",
                              },
                              display: "flex",
                              mb: 1,
                            }}
                          >
                            <a
                              href={item.app.google_play_link}
                              target="_blank"
                              aria-label="Download on Google Play"
                            >
                              <Box
                                component="img"
                                sx={{
                                  width: {
                                    xs: "80px",
                                    md: "100px",
                                    lg: "140px",
                                  },
                                  height: {
                                    xs: "30px",
                                    md: "35px",
                                    lg: "45px",
                                  },
                                  cursor: "pointer",
                                  mt: {
                                    xs: 1,
                                    md: 1,
                                  },
                                }}
                                src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/google-play-light.png"
                                alt="Download on Google Play"
                                loading="lazy"
                              />
                            </a>
                            <a
                              href={item.app.app_store_link}
                              target="_blank"
                              aria-label="Download on the App Store"
                            >
                              <Box
                                component="img"
                                sx={{
                                  width: {
                                    xs: "80px",
                                    md: "100px",
                                    lg: "140px",
                                  },
                                  height: {
                                    xs: "30px",
                                    md: "35px",
                                    lg: "45px",
                                  },
                                  mx: {
                                    xs: 1,
                                    md: 2,
                                  },
                                  mt: {
                                    xs: 1,
                                    md: 1,
                                  },
                                  cursor: "pointer",
                                }}
                                src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/app-store-light.png"
                                alt="Download on the App Store"
                                loading="lazy"
                              />
                            </a>
                          </Box>
                          {item.app.qr_code && (
                            <Box
                              sx={{
                                mx: {
                                  sm: "inherit",
                                  xs: "auto",
                                },
                              }}
                            >
                              <Box
                                component="img"
                                sx={{
                                  width: {
                                    xs: "60px",
                                    md: "80px",
                                    lg: "120px",
                                  },
                                  mb: {
                                    xs: 3,
                                    sm: 0,
                                  },
                                }}
                                src="/qr/brokrage.svg"
                                alt="qr-code"
                                loading="lazy"
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>

                    <Box
                      sx={{
                        height: {
                          xs: "220px",
                          sm: "280px",
                          md: "380px",
                          lg: "500px",
                          xxl: "600px",
                        },
                        order: { xs: 1, sm: 2 },
                      }}
                    >
                      <img
                        src={item.img}
                        alt={item.alt}
                        height="100%"
                        loading="lazy"
                      />
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
